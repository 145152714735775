<template>
  <div>
    <div class="router-view">
      <section class="aura-header">
        <div class="aura-title">
          <label class="is-size-3"> <span class="has-text-weight-bold">Kappi</span> | Background check</label>
        </div>
      </section>
      <router-view />
    </div>
    <sidebar>
      <div class="block micro-logo">
        <img src="../assets/aura_logo_white.svg" alt="image" />
      </div>
      <div class="menu">
        <router-link to="/hp/dashboard">
          <b-icon icon="shield-search" size="is-medium"> </b-icon>
        </router-link>
        <span :class="{ 'hidden-span': permissionsEmbedded }">
          <router-link to="/hp/embedded">
            <b-icon icon="chart-pie" size="is-medium"> </b-icon>
          </router-link>
        </span>
      </div>

      <div class="menu menu-inferior">
        <router-link to="/" @click="logout">
          <b-icon icon="logout" size="is-medium"> </b-icon>
          <!-- Arquivo -->
        </router-link>
      </div>
    </sidebar>
  </div>
</template>

<style scoped>
sidebar {
  width: 50px;
  background-color: #e30c84;
  height: 100%;
  top: 0px;
  position: absolute;
}

.hidden-span {
  display: none;
}

.aura-header {
  padding: 30px 0px;
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.7;
  letter-spacing: normal;
  text-align: left;
  color: #717680;
}

.aura-title {
  margin: 0 30px 0 0;
}

.router-view {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f1f5fa;
  padding-left: 70px;
}

.micro-logo {
  width: 24px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ffffff80;
}

.menu-superior .mn-selected {
  background-color: #e30c84;
  color: white;
  border-radius: 4px;
}

.menu {
  width: 35px;
  margin: 20px auto 0;
}

.menu span {
  margin-bottom: 15px;
}

.menu a {
  color: white;
}

.menu-inferior {
  bottom: 34px;
  position: absolute;
  left: 10px;
}
</style>

<script>
import { getAuth, signOut } from "firebase/auth";
import users from './../../../../functions/middleware/session.js';

export default {
  data() {
    return {
      name_analyze: "",
      showSidebar: true,
      isLoading: false,
      isImageModalActive: false,
      cnpjInformado: "3366946000245",
      permissionsEmbedded: true,
      username: "",
      client: "",
      data: [],
      columns: [
        {
          field: "cnpj",
          label: "CNPJ",
        },
        {
          field: "identificador_matriz_filial",
          label: "Matriz/Filiar",
        },
        {
          field: "razao_social",
          label: "Razão Socal",
          centered: true,
        },
        {
          field: "municipio",
          label: "Municipio",
        },
        {
          field: "uf",
          label: "Uf",
        },
      ],
    };
  },
  mounted() {
    this.username = this.$session.get("email");
    this.client = users.getUser(this.$session.get("email")) || "aura";

    if (["operacao"].includes(this.client) ||
      [
        'carlos.salgado@kappi.io',
        'leonardo.artiero@kappi.io',
        'fernando.maia@kappi.io',
        'caio.mello@kappi.io',
        'gabriel.fachini@agcapital.com.br',
        'andre.papaleo@abstrato.ventures',
        'luiz.fernando@portobello.com.br',
        'amanda.rufino@portobello.com.br',
        'nattan.silva@portobello.com.br',
        'simone.constantino@portobello.com.br',
        'ana.pereira@portobello.com.br',
        'maryana.lohse@portobello.com.br'
      ].includes(this.username)) {
      this.permissionsEmbedded = false;
    }

  },
  methods: {
    expandMenu: function () {
      this.reduce = !this.reduce;
    },
    submit: async function (e) {
      e.preventDefault();

      let cnpjs = this.data.map((x) => x.cnpj);
      let data = {
        name: this.name_analyze || "",
        createdAt: new Date().toISOString(),
        cnpjs: cnpjs,
      };

      const rawResponse = await fetch("/api/compliance/pj", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const content = await rawResponse.json();

      this.$router.push("/compliance/pj/analyse/" + content);
      this.isImageModalActive = false;
    },
    getCompanie: async function () {
      this.isLoading = true;
      let data = await fetch("/api/find/" + this.cnpjInformado);
      let res = await data.json();
      this.data = this.data.concat(res);
      this.isLoading = false;
    },
    clear: function () {
      this.cnpjInformado = null;
      this.data = [];
      this.isImageModalActive = false;
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$router.push("/auth/login");
        })
        .catch((error) => {
          this.errors.push(error.message);
        });
    },
  },
};
</script>